/*
* BASE DEPENDENCIES
*/
/*****************************************
* Blackwell - MIXINS
*****************************************/
/*****************************************
* blackwell - FUNCTIONS
*****************************************/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	BUTTONS
==============================*/
.error-404__flair {
  font-size: 28vw;
  font-family: "Titillium Web", sans-serif;
  color: #00569d;
}

@media screen and (min-width: 1280px) {
  .error-404__flair {
    font-size: 320px;
  }
}
